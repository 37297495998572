import { useEffect, useState } from 'react';

import { createPortal } from 'react-dom';
import { useBlockScroll } from 'hooks/useBlockScroll';

export type ReactPortalProps = {
  children: React.ReactNode;
  wrapperId: string;
};

export const ReactPortal = ({ children, wrapperId }: ReactPortalProps) => {
  const [wrapper, setWrapper] = useState<Element | null>(null);
  const [isCreated, setIsCreated] = useState(false);

  const { doLock, doUnlock } = useBlockScroll();

  useEffect(() => {
    let element = document.getElementById(wrapperId);
    if (!element) {
      setIsCreated(true);
      const wrapper = document.createElement('section');
      wrapper.setAttribute('id', wrapperId);
      doLock();
      document.body.appendChild(wrapper);
      element = wrapper;
      setWrapper(element);
    }
  }, [wrapper, wrapperId, doLock]);

  useEffect(() => {
    return () => {
      if (isCreated && wrapper) {
        document.body.removeChild(wrapper);
        setWrapper(null);
        setIsCreated(false);
        doUnlock();
      }
    };
  }, [isCreated, wrapper, doUnlock]);

  if (wrapper === null) return null;

  return createPortal(children, wrapper);
};
