import { InputHTMLAttributes, useState } from 'react';
import { Controller, FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { Icon, IconsEnum } from '../../Icons';
import { isControlledInput } from '../utils';

import styles from './PasswordInput.module.scss';

export type PasswordInputProps = {
  label: string;
  placeholder?: string;
  trailingIcon?: IconsEnum;
  errorMessage?: string;
  isErrorHidden?: boolean;
  hasMarginRight?: boolean;
  hasMarginBottom?: boolean;
  hasMarginTop?: boolean;
  hasMarginLeft?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export type ControlledPasswordInputProps<
  T extends FieldValues = FieldValues,
  K extends Path<T> = Path<T>
> = PasswordInputProps & UseControllerProps<T, K>;

export function PasswordInput<T extends FieldValues = FieldValues>(props: ControlledPasswordInputProps<T>): JSX.Element;
export function PasswordInput(props: PasswordInputProps): JSX.Element;
export function PasswordInput({ isErrorHidden, ...props }: PasswordInputProps | ControlledPasswordInputProps) {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  if (isControlledInput<ControlledPasswordInputProps>(props)) {
    const { hasMarginTop, hasMarginRight, hasMarginBottom, hasMarginLeft, ...defaultProps } = props;
    return (
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => {
          const currentError = props.control?._formState.errors[props.name];
          return (
            <div
              className={`${styles.container}
            ${hasMarginTop ? styles.containerMarginTop : ''}
            ${hasMarginRight ? styles.containerMarginRight : ''}
            ${hasMarginBottom ? styles.containerMarginBottom : ''}
            ${hasMarginLeft ? styles.containerMarginLeft : ''} 
          `}
            >
              <label htmlFor={props.label} className={styles.label}>
                {props.label}
              </label>
              <div className={styles.inputWrapper}>
                <input
                  {...defaultProps}
                  id={props.label}
                  type={passwordShown ? 'text' : 'password'}
                  className={`${styles.input} ${currentError && styles.inputError}`}
                  ref={ref}
                  onChange={(e) => {
                    onChange(e);
                    props.onChange && props.onChange(e);
                  }}
                  onBlur={(e) => {
                    onBlur();
                    props.onBlur && props.onBlur(e);
                  }}
                  value={value || ''}
                />
                <button
                  type="button"
                  className={`${styles.pswBtn} ${error && styles.animate}`}
                  onClick={() => togglePasswordVisibility()}
                >
                  <Icon
                    iconId={passwordShown ? IconsEnum.EYE : IconsEnum.EYE_OFF}
                    className={styles.icon}
                    color={'var(--color-on-bg-primary)'}
                  />
                </button>
                {error && (
                  <div className={styles.trailingIconContainer}>
                    <Icon iconId={props.trailingIcon ?? IconsEnum.CLOSE_CIRCLE} className={styles.icon} />
                  </div>
                )}
              </div>
              {!isErrorHidden && <span className={styles.errorMessage}>{error?.message}&nbsp;</span>}
            </div>
          );
        }}
      />
    );
  }

  return (
    <div className={styles.container}>
      <label htmlFor={props.label} className={styles.label}>
        {props.label}
      </label>
      <div className={styles.inputWrapper}>
        <input
          id={props.label}
          type={passwordShown ? 'text' : 'password'}
          className={`${styles.input} ${props.errorMessage && styles.inputError}`}
          onChange={(e) => {
            props.onChange && props.onChange(e);
          }}
          onBlur={(e) => {
            props.onBlur && props.onBlur(e);
          }}
          value={props.value || ''}
          {...props}
        />
        <button
          type="button"
          className={`${styles.pswBtn} ${props.errorMessage && styles.animate}`}
          onClick={() => togglePasswordVisibility()}
        >
          <Icon
            iconId={passwordShown ? IconsEnum.EYE : IconsEnum.EYE_OFF}
            className={styles.icon}
            color={'var(--color-on-bg-primary)'}
          />
        </button>
        {props.errorMessage && (
          <div className={styles.trailingIconContainer}>
            <Icon iconId={props.trailingIcon ?? IconsEnum.CLOSE_CIRCLE} className={styles.icon} />
          </div>
        )}
      </div>
      <span className={styles.errorMessage}>{props.errorMessage}&nbsp;</span>
    </div>
  );
}
