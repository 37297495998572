import {
  resetStateDashboard,
  setAlertMessage,
  setAutoEsclusionString,
  setWalletData,
} from 'features/dashboard/dashboardSlice';
import { useGetLabelsByKeys } from 'hooks/useLingUI';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FIRTS_LOGIN_COOKIE, IS_LOGGED, PROVENIENCE_QUERY_PARAM } from 'utility/constant';
import { detectMob, gtag, isTruthy, killDigitalAssistant } from 'utility/functions';
import { PathName, getRouteByKey } from 'utility/routes';

import { zodResolver } from '@hookform/resolvers/zod';
import { IconsEnum } from 'components/Icons';
import { BackgroundImage } from 'components/backgroundImage';
import { BannerInformation } from 'components/bannerInformation';
import { Button } from 'components/button/Button';
import { PasswordInput } from 'components/inputs/passwordInput';
import { TextInput } from 'components/inputs/textInput';
import { apiSlice } from 'features/api/apiSlice';
import { useLazyGetCachedProfileQuery } from 'features/api/userSlice';
import { selectStringAutoesclusion } from 'features/dashboard/selectors';
import { dialogInfoChange } from 'features/modal/modalSlice';
import { useAppDispatch } from 'lib/centralStore';
import { stringNotEmpty } from 'lib/labels';
import Persist, { StorageKind } from 'lib/persist';
import { setCookie } from 'lib/persist/cookie';
import { getNonce } from 'lib/policy';
import policy from 'lib/policy/types';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useSelector } from 'react-redux';
import { AuthError } from 'types/errors';
import { AuthErrorType } from 'types/login';
import { z } from 'zod';
import styles from './LoginModalBody.module.scss';
import addMinutes from 'date-fns/addMinutes';
import { useSession, signIn } from 'next-auth/react';
import { mutex } from 'components/refreshTokenEngine';
import { requestGeoLocation, setUserID } from 'features/geoComply/actions';

const { IOVATION_URL } = policy;

export type LoginModalBodyProps = {
  title?: string;
  buttonLabel: string;
  redirectOnSuccess?: string;
  handleClose: Dispatch<SetStateAction<boolean>>;
};

export const LoginModalBody = ({ title, buttonLabel, handleClose, redirectOnSuccess }: LoginModalBodyProps) => {
  const dispatch = useAppDispatch();
  const { asPath, push } = useRouter();

  const { data: session, status } = useSession();

  const [errorText, setErrorText] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isNewLogin, setNewLogin] = useState(false);
  const [urlRedirect, setUrlRedirect] = useState<string>();
  const [urlLabelButton, setUrlLabelButton] = useState<string>();
  const [labelConferma, labelMaggioriInfo, blockedAccountLoginError, usernamePasswordError, autoEsclusionMoreInfoUrl] =
    useGetLabelsByKeys([
      'conferma',
      'maggiori-info',
      'blocked-account-login-error',
      'username-password-error',
      'autoesclusion-more-info-url',
    ]);

  const registrationUrl = useMemo(() => getRouteByKey(PathName.registrationUrl), []);
  const slugProvenienza = useMemo(() => {
    const splittedSlug = asPath.split('/');
    const slug = splittedSlug.length > 1 ? splittedSlug[1] : splittedSlug[0];
    return slug || 'homepage';
  }, [asPath]);

  const stringAutoesclusion = useSelector(selectStringAutoesclusion);
  const [getSessionCacheProfile] = useLazyGetCachedProfileQuery();

  const schema = z.object({
    username: stringNotEmpty,
    password: stringNotEmpty,
  });
  type ValidationSchema = z.infer<typeof schema>;

  const { control, handleSubmit } = useForm<ValidationSchema>({
    resolver: zodResolver(schema),
  });

  const getSessionCacheProfileCallback = useCallback(async () => {
    const { data } = await getSessionCacheProfile();
    const { msgctr, testo_notifica, flag_notifica, urL_notifica } = data ?? {};

    dispatch(setUserID(data?.carta!));
    dispatch(requestGeoLocation('Login'));

    dispatch(
      setWalletData({
        saldo: Number(data?.saldo_carta ?? 0),
        bonusGold: Number(data?.saldo_wagering ?? 0),
        updateWalletTimestamp: addMinutes(Date.now(), 5).getTime(),
      })
    );

    if (testo_notifica) {
      dispatch(
        dialogInfoChange({
          isOpen: true,
          title: testo_notifica,
          buttons: [
            <Button
              key={0}
              visualizationType={'primary'}
              onClick={() => {
                dispatch(dialogInfoChange({ isOpen: false }));
                if (isTruthy(flag_notifica) && urL_notifica) {
                  push(urL_notifica);
                }
              }}
            >
              {labelConferma}
            </Button>,
          ],
        })
      );
    }

    if (msgctr?.messaggio) {
      const iconEnumKey = Object.keys(IconsEnum).find(
        (key) => IconsEnum[key] === `message-center-${msgctr?.icona?.replace('.svg', '')}`
      );
      dispatch(
        setAlertMessage({
          icon: iconEnumKey ? IconsEnum[iconEnumKey] : IconsEnum.GEAR,
          message: msgctr.messaggio,
          color: '#fffff',
          btn: msgctr.btn && msgctr.btn.length > 0 ? msgctr.btn : undefined,
          animation: false,
        })
      );
    }
  }, [getSessionCacheProfile, dispatch]);

  useLayoutEffect(() => {
    if (isNewLogin) {
      if (status === 'authenticated') {
        if (redirectOnSuccess) {
          push(redirectOnSuccess);
        } else {
          handleClose(true);
        }
      }
    }
  }, [redirectOnSuccess, status, isNewLogin, handleClose]);

  useEffect(() => {
    if (isNewLogin && session) {
      getSessionCacheProfileCallback();
      killDigitalAssistant();
    }
  }, [session, isNewLogin, getSessionCacheProfileCallback]);

  const submit: SubmitHandler<ValidationSchema> = async ({ password, username }) => {
    setIsLoading(true);
    setErrorText(undefined);

    dispatch(apiSlice.util.resetApiState());
    dispatch(resetStateDashboard());

    if (stringAutoesclusion) dispatch(setAutoEsclusionString(undefined));

    let iovationCode!: string;
    const iovationEnabled = isTruthy(`${process.env.NEXT_PUBLIC_IOVATION_CODE_ENABLED}`);

    // @ts-ignore
    if (iovationEnabled && window?.IGLOO?.getBlackbox) {
      // @ts-ignore
      iovationCode = window.IGLOO.getBlackbox().blackbox;
    }

    try {
      // lock other api calls during the login intervall
      await mutex.acquire();
    } catch (ex) {
      console.log('mutex-exc', ex);
    }

    const response = await signIn('credentials', {
      redirect: false,
      username,
      password,
      iovationCode,
      frontendType: detectMob() ? '60' : '1',
    });

    Persist(StorageKind.cookie).setItem(IS_LOGGED, `${response?.ok}`);

    const isSuccess = isTruthy(response?.ok);

    if (isSuccess) {
      gtag({ section: 'Login Modal', detail: 'Login Success', event: 'Login Success' }, true);

      const firstLogin = document.cookie.includes(FIRTS_LOGIN_COOKIE);
      if (!firstLogin) {
        setCookie(FIRTS_LOGIN_COOKIE, new Date().getTime().toString(), 30);
      }
    } else {
      const { data } = JSON.parse(`${response?.error ?? '{}'}`) as AuthError;
      setErrorText(
        data.message === AuthErrorType.InvalidGrant
          ? usernamePasswordError
          : data.message === AuthErrorType.BlockedAccount
          ? blockedAccountLoginError
          : data.message
      );
      data.urlRedirect && setUrlRedirect(data.urlRedirect);
      data.urlLabelButton && setUrlLabelButton(data.urlLabelButton);
    }

    if (isSuccess) {
      // await an arbitrary delay to allow new session data to be loaded
      await new Promise((resolve) => setTimeout(resolve, 499));
    }
    try {
      // unlock other api calls during the login intervall
      await mutex.release();
    } catch (ex) {
      console.log('mutex-exc', ex);
    }

    setIsLoading(false);
    setNewLogin(isSuccess);
  };

  return (
    <React.Fragment>
      <Script src={IOVATION_URL} nonce={getNonce()} />
      {errorText && !urlRedirect && !urlLabelButton ? (
        <BannerInformation text={errorText!} type="error" visible={!!errorText} layoutType="big" />
      ) : (
        <BannerInformation
          text={errorText!}
          type="error"
          visible={!!errorText}
          layoutType="big"
          redirectButton={{
            element: urlLabelButton!,
            url: urlRedirect!,
          }}
        />
      )}
      {stringAutoesclusion && !errorText && (
        <BannerInformation
          text={stringAutoesclusion!}
          type="success"
          visible={!!stringAutoesclusion}
          redirectButton={{
            element: <span>{labelMaggioriInfo}</span>,
            url: autoEsclusionMoreInfoUrl,
          }}
        />
      )}
      <div className={styles.container}>
        <BackgroundImage />
        <form className={styles.form} onSubmit={handleSubmit(submit)}>
          {title && <span className={styles.title}>{title}</span>}
          <TextInput
            name="username"
            control={control}
            label={'username'}
            autoComplete="username"
            placeholder={'Inserisci la tua username'}
            hasMarginBottom
          />
          <PasswordInput
            autoComplete="current-password"
            label={'Password'}
            placeholder={'Inserisci la tua password'}
            control={control}
            name={'password'}
          />
          <a href={'/user/password'} className={styles.link}>
            Hai dimenticato la tua password?
          </a>
          <Button type="submit" className={styles.button} isLoading={isLoading}>
            {buttonLabel}
          </Button>
        </form>
        {/* TODO: ADD LABELS TO CMS */}
        <div className={styles.boxesContainer}>
          <article className={styles.box}>
            <p className={styles.textsContainer}>
              <strong className={styles.boxTitle}>
                Non hai un conto Snai?
                <br />
                Ottieni subito 15 € free!
              </strong>
            </p>
            <button
              className={styles.boxBtn}
              onClick={() => push(`${registrationUrl}?${PROVENIENCE_QUERY_PARAM}=newsnai_${slugProvenienza}`)}
            >
              Registrati
            </button>
          </article>
          <article className={styles.box}>
            <p className={styles.textsContainer}>
              <strong className={styles.boxTitle}>Hai già un conto SNAI in un negozio e lo vuoi attivare?</strong>
            </p>
            <button className={styles.boxBtn} onClick={() => push('/user/password/pdv')}>
              Attiva
            </button>
          </article>
        </div>
      </div>
    </React.Fragment>
  );
};
