import { isClientSide } from 'utility/functions';
import { useEffect } from 'react';

const doLock = () => {
  if (!isClientSide()) return;
  document.documentElement.classList.add('no-scroll');
  // document.body.classList.add('no-scroll');
};

const doUnlock = () => {
  if (!isClientSide()) return;
  document.documentElement.classList.remove('no-scroll');
  // document.body.classList.remove('no-scroll');
};

export const useBlockScroll = (isBlocked = false) => {
  useEffect(() => {
    if (isBlocked) {
      doLock();
    } else {
      doUnlock();
    }
    return doUnlock;
  }, [isBlocked]);

  return { doLock, doUnlock };
};
