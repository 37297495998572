import classNames from 'classnames';
import { ModalHeader, ModalHeaderType } from '../modalHeader';
import styles from './Modal.module.scss';

export type ModalProps = {
  children: React.ReactNode;
  label?: string;
  containerClass?: string;
  headerClass?: string;
  overlayClassName?: string;

  // handleClose: Function; //Dispatch<SetStateAction<boolean>>;
} & ModalHeaderType;

export const Modal = ({
  children,
  label,
  modalCategory,
  modalType,
  logoBrand,
  onClose,
  headerClass,
  containerClass,
  overlayClassName,
  showCloseButton,
}: ModalProps) => {
  return (
    <div className={classNames(overlayClassName, styles.overlay)}>
      <dialog className={`${styles.modal} ${containerClass && containerClass} ${styles[modalType]}`}>
        <ModalHeader
          modalType={modalType}
          label={label}
          modalCategory={modalCategory}
          logoBrand={logoBrand}
          onClose={onClose}
          headerClass={headerClass}
          showCloseButton={showCloseButton}
        />
        {children}
      </dialog>
    </div>
  );
};
